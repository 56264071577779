import { ArrowLeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import Link, { navigate } from 'gatsby-link';
import React from 'react';
import FmIcon from '../assets/style/icons/fairmatic-logo.svg';

function Helpcenter() {
  return (
    <div style={{ background: '#f2f5f8', height: '100vh', fontWeight: 700 }}>
      <div className="header">
        <div className="header_back">
          <div
            className="px-3"
            style={{
              width: '15%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Link to="/app/dashboard">
              <FmIcon style={{ fontSize: '48px' }} className="fm_logo" />
            </Link>
          </div>
          <div className="px-3">
            <h2 className="page__title">Help Center</h2>
          </div>
        </div>
      </div>

      <Card style={{ width: '70%', margin: '30px auto' }}>
        <div
          style={{ marginBottom: '15px', color: 'green', cursor: 'pointer' }}
          onClick={() => navigate('/app/dashboard/overview')}
        >
          <ArrowLeftOutlined /> Go back
        </div>
        <div
          style={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <QuestionCircleOutlined
            style={{ fontSize: '24px', color: 'gray', marginRight: '5px' }}
          />
          Do you have any questions, concerns or feedback for us? Kindly reach
          out to us at
          <Link
            href="mailto:support@fairmatic.com"
            target="_blank"
            style={{ color: '#396DF4', marginLeft: '5px' }}
          >
            support@fairmatic.com
          </Link>
        </div>
      </Card>
    </div>
  );
}

export default Helpcenter;
